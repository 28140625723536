<template>
  <div class="mb-2">
    <div :style="[section.embed_url ? {background: 'black'} : {} ]" class="preview_audio_video">
      <div v-if="!section.url"></div>
      <div v-if="!section.url" class="default_audio_video">
        <i class="fab fa-youtube d-flex align-items-center justify-content-center"></i>
      </div>

      <div v-else-if="isFaceBookEmbed()"class="fb-video iframe" :data-href="section.url"
           :class="section.title && section.title ? 'isVideoWithTitle' : 'isVideoWithNoTitle'"
           :data-height="section.height" data-width="auto" data-show-text="false">
        <div class="fb-xfbml-parse-ignore">
          <blockquote :cite="section.url"></blockquote>
        </div>
      </div>

      <div v-else-if="isInstagramEmbed()" :id="section._id ? section._id : 'instagram'"  class="instagram iframe">
        <div :class="section.title && section.title ? 'isVideoWithTitle' : 'isVideoWithNoTitle'"></div>
      </div>

      <iframe v-else :height="section.height_enabled ? section.height : '199'"
              :class="{'apply_radius_to_iframe':!section.title}" class="iframe" allowfullscreen allowtransparency="true"
              scrolling="no" frameborder="no" :src="getEmbed()"></iframe>

      <div v-if="section.title" :style="bioSectionThemeStyleBinding('background',getBioLinkAdds)" class="d-block title">
        <p :style="bioSectionThemeStyleBinding('text',getBioLinkAdds)">{{ section.title }}</p></div>
    </div>
  </div>
</template>
<script>


export default ({
  props: [
    'isPreview',
    'section',
    'getBioLinkAdds'
  ],
  created () {
    if(this.section && this.section.url) {
      this.facebookRefreshEmbed()
      this.instagramRefreshEmbed()
    }
  },
  methods: {
    isFaceBookEmbed () {
      return this.section && this.section.url && this.section.url.includes('facebook') || this.section.url.includes('fb')
    },
    isInstagramEmbed () {
      return this.section && this.section.url && this.section.url.includes('instagram')
    },
    getEmbed () {
      return this.section && this.section.embed_url && this.section.embed_url.includes('twitch') ? this.section.embed_url + `&parent=${window.location.host}` : this.section.embed_url
    },
    facebookRefreshEmbed () {
      if (this.isFaceBookEmbed()) {
        setTimeout(async () => {
          await FB.XFBML.parse()
        }, 1000)
      }
    },
    instagramRefreshEmbed () {
      if (this.isInstagramEmbed()) {

        setTimeout(async () => {

          let instagram = null

          if(this.section && !this.section._id) {
            instagram = document.getElementById('instagram')
          }

          if(this.section && this.section._id) {
            instagram =  document.getElementById(this.section._id)
          }

          if(instagram) {

            let isVideoWithNoTitle = instagram.getElementsByClassName('isVideoWithNoTitle')
            let isVideoWithTitle = instagram.getElementsByClassName('isVideoWithTitle')

            if (this.section && !this.section.title) {
              isVideoWithNoTitle[0].innerHTML = `<blockquote class="instagram-media" data-instgrm-permalink="${this.section.url}"></blockquote>`;
              await window.instgrm.Embeds.process();
            }

            if (this.section && this.section.title) {
              isVideoWithTitle[0].innerHTML = `<blockquote class="instagram-media" data-instgrm-permalink="${this.section.url}"></blockquote>`;
              await window.instgrm.Embeds.process();
            }

          }
        }, 2000)
      }
    },

  },
  watch: {
    'section.url' (value) {
      if(value) {
        this.facebookRefreshEmbed()
        this.instagramRefreshEmbed()
      }
    },
    'section.height' (value) {
      if(value) {
        this.facebookRefreshEmbed()
      }
    },
    'section.title' (value) {
      if(value) {
        this.facebookRefreshEmbed()
      }
    }
  }
})
</script>
